import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components';
import {Link} from 'gatsby';

import Nav from '../components/nav/nav';

import RowWrapper from "../utils/rowWrapper"



const Container = styled.section`
  height: 100vh;
  padding-top: 5rem;
  position: relative;
`

const LogoContainer = styled.div`
  width: 8rem;
  margin: 0 auto;

  img {
    width: 100%;
  }
`

const Title = styled.h1`
  font-size: 13rem;
  text-align: center;
  margin-top: 20%;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 6rem;
  }
`

const Subtitle = styled.h3`
  text-align: center;
  font-size: 3rem;
  color: ${props => props.theme.colors.color};
  margin-top: 6rem;

  @media ${props => props.theme.breaks.down('sm')} {
    font-size: 2rem;
  }
`

const NavContainer = styled.div`
  position: fixed;
  top: 5rem;
  right: 5rem;
  z-index: 100;
`


// markup
const MissingPage = () => {


  return (
    <Layout pageTitle="Urbo - 404">
      <Container>

      {/* <NavContainer>
        <Nav />
      </NavContainer> */}

        <RowWrapper>
        {/* <LogoContainer>
        </LogoContainer> */}

        <Title>404</Title>
        <Subtitle>Spacing out? There’s nothing here.</Subtitle>
          

        </RowWrapper>

      </Container>
    </Layout>
  )
}

export default MissingPage

